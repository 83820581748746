<template>
  <div class="qc-success">
    <div class="success-wrap">
      <img :src="successIcon" alt="" class="success-img">
      <div class="desc">提交成功</div>
      <van-button plain type="primary" class="btn" @click="btnClick">完成</van-button>
    </div>
  </div>
</template>

<script>
  import {appAction, uwStatisticAction} from "../../utils/util";
import { PREVIOUS_PAGE} from "../../utils/constantNum";
import common from "../../utils/common";

export default {
  data() {
    return {
      successIcon: require('@/assets/img/mine/successIcon.png')
    }
  },
  mounted() {
    uwStatisticAction('/cashPaySuccess','设备押金确认成功')
  },
  methods:{
    btnClick() {
      if (common.getItem('jumpFrom') != undefined ) {
        appAction(PREVIOUS_PAGE)
      }else {
        this.$router.push({name: 'mine'})
      }

    }
  }

}
</script>

<style lang="less" scoped>
.qc-success{
  text-align: center;
  background: #F7F8F9;
  min-height: 100%;
  .success-wrap{
    background: #fff;
  }
  .success-img{
    width: 1.28rem;
    height: 1.2rem;
    margin-top: 0.64rem;
  }
  .desc{
    font-size: 0.36rem;
    margin-top: 0.4rem;
  }
  .btn{
    margin-top: 1.6rem;
    color: #3F7C53;
    border-color: #3F7C53;
    width: 5.6rem;
    height: 0.98rem;
    font-size: 0.36rem;
    margin-bottom: 0.68rem;
  }
}
</style>